class PeriodOverPeriodGrowth {
  constructor(x = [], y = []) {
    this.x = x;
    this.y = y;
  }
  predict(x) {
    const firstYearX = x - 24;
    const secondYearX = x - 12;

    const firstYearValue = this.y[firstYearX - 1] || 0;
    const secondYearValue = this.y[secondYearX - 1] || 0;

    if (!firstYearValue) {
      return 0;
    }

    const percentChange =
      (secondYearValue - firstYearValue) / Math.abs(firstYearValue);

    const result = secondYearValue * percentChange + secondYearValue;

    return result;
  }
}

module.exports.PeriodOverPeriodGrowth = PeriodOverPeriodGrowth;
