const color = require('color');

function applyAlphaToThemeColor(currentColor, themeColor) {
  if (!currentColor) return themeColor;

  const currentAlpha = color(currentColor).alpha();
  if (currentAlpha !== 1) {
    return color(themeColor).alpha(currentAlpha).string();
  }

  return themeColor;
}

module.exports = applyAlphaToThemeColor;
