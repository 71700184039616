const Color = require('color');

/**
 * Calculates the matte color of a semi-opaque color on top of a solid color.
 *
 * @param {string} solidColor - The solid color in hex (#ff0000), rgb (rgb(255, 0, 0)), or "transparent".
 * @param {string} semiOpaqueColor - The semi-opaque color in hex (#0000ff80), rgb (rgba(0, 0, 255, 0.5)), or "transparent".
 * @returns {string} The matte color in the same format as the solid color parameter.
 */
const calculateMatteColor = (solidColor, semiOpaqueColor) => {
  if (
    (solidColor === 'transparent' && semiOpaqueColor === 'transparent') ||
    semiOpaqueColor === 'transparent'
  ) {
    return undefined;
  }

  // Check if semiOpaqueColor is in rgba format and has an opacity of 0
  if (/rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*0\s*\)/i.test(semiOpaqueColor)) {
    return 'rgba(0, 0, 0, 0)';
  }

  // Convert the solid color to Color object
  const solidColorObj =
    solidColor === 'transparent' ? Color.rgb(0, 0, 0, 0) : Color(solidColor);

  // Convert the semi-opaque color to Color object
  const semiOpaqueColorObj =
    semiOpaqueColor === 'transparent'
      ? Color.rgb(0, 0, 0, 0)
      : Color(semiOpaqueColor);

  // Extract the alpha channel from the semi-opaque color
  const alpha = semiOpaqueColorObj.alpha();

  // Calculate the matte color
  const matteColorObj = semiOpaqueColorObj
    .alpha(alpha)
    .mix(solidColorObj.alpha(1 - alpha));

  // Return the matte color as a string in the original format
  return matteColorObj.alpha(1).rgb().string();
};

module.exports = calculateMatteColor;
