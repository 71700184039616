module.exports = function wait(milliseconds) {
  return new Promise((resolve) => {
    if (milliseconds === undefined || milliseconds >= 0) {
      return setTimeout(resolve, milliseconds || 0);
    }

    if (typeof window === 'undefined' || !window.requestAnimationFrame) {
      setImmediate(resolve);
    } else {
      window.requestAnimationFrame(resolve);
    }
  });
};
