const wait = require('./wait');

const isNodeJs =
  typeof process !== 'undefined' && typeof process.hrtime !== 'undefined';
let lastAwait;
let measurementInProgress = false;
let measurementsInProgress = 0;
const useOldAwaitForNodeJs = true;

function smartWaitBegin() {
  measurementsInProgress++;

  if (!measurementInProgress || isNodeJs) {
    measurementInProgress = true;

    // Only reset the timer if this is the only measurement currently happening
    lastAwait = performance.now();
  }
}

function smartWaitEnd() {
  measurementsInProgress--;

  if (measurementsInProgress <= 0) {
    measurementsInProgress = 0;
    measurementInProgress = false;
  }
}

/**
 * @description Wrapper for expensive calls. Callback will be passed an awaiter, which when called returns 'false' or a promise. If it's a promise, await it to defer CPU time to the browser for user interaction.
 * @param {Function} callback Should contain the expensive code to run. Will be passed an awaiter (a synchronous function) as the 1st argument. When the awaiter is evaluated, it will either return 'false' or a promise. If it's a promise, await it.
 * @param {Number} maxElapsedTimeInMsBeforeDeferring Optional, defaults to 250 (ms). This is the length of time waited before returning a promise via the awaiter.
 * @example
 * await smartWait(async (awaiter) => {
 *  for (var i = 0; i < 1000000; i++) {
 *    if (i % 100 === 0) {
 *      const needToWait = awaiter();
 *      if (!!needToWait) {
 *        await needToWait;
 *      }
 *    }
 *    // Do something expensive
 *  }
 *});
 */
async function smartWait(callback, maxElapsedTimeInMsBeforeDeferring) {
  if (!callback || !(callback instanceof Function)) {
    throw new Error('Callback must be a function');
  }

  maxElapsedTimeInMsBeforeDeferring =
    maxElapsedTimeInMsBeforeDeferring || (isNodeJs ? 100 : 250);

  const nodeLastAwait = isNodeJs ? process.hrtime.bigint() / 1000000n : 0;
  const awaiter =
    isNodeJs && useOldAwaitForNodeJs
      ? wait
      : (milliseconds) => {
          let now =
            typeof process !== 'undefined' && process.hrtime
              ? process.hrtime.bigint() / 1000000n
              : performance.now();

          const compareTo = isNodeJs ? nodeLastAwait : lastAwait;

          if (now - compareTo < maxElapsedTimeInMsBeforeDeferring) {
            // Because of the overhead of async/await ops, the caller should only await if a promise is returned
            return false;
          }

          if (isNodeJs) {
            nodeLastAwait = now;
          } else {
            lastAwait = now;
          }

          return new Promise((resolve) => {
            if (milliseconds === undefined || milliseconds >= 0) {
              return setTimeout(resolve, milliseconds || 0);
            }

            window.requestAnimationFrame(resolve);
          });
        };

  !isNodeJs && smartWaitBegin();

  await callback(awaiter);

  !isNodeJs && smartWaitEnd();
}

module.exports.smartWait = smartWait;

/**
 * @description Wrapper for expensive calls. Callback will be passed an awaiter, which when called returns 'false' or a promise. If it's a promise, await it to defer CPU time to the browser for user interaction.
 * @param {string} description Description of the code to run. This will output the measured time to run (in ms) to the console as follows: "Execution time (description): __ms"
 * @param {Function} callback Should contain the expensive code to run. Will be passed an awaiter (a synchronous function) as the 1st argument. When the awaiter is evaluated, it will either return 'false' or a promise. If it's a promise, await it.
 * @param {Number} maxElapsedTimeInMsBeforeDeferring Optional, defaults to 250 (ms). This is the length of time waited before returning a promise via the awaiter.
 * @example
 * await smartWait(async (awaiter) => {
 *  for (var i = 0; i < 1000000; i++) {
 *    if (i % 100 === 0) {
 *      const needToWait = awaiter();
 *      if (!!needToWait) {
 *        await needToWait;
 *      }
 *    }
 *    // Do something expensive
 *  }
 *});
 */
module.exports.smartWaitAndMeasure = async function (
  description,
  callback,
  maxElapsedTimeInMsBeforeDeferring
) {
  const t0 = isNodeJs ? process.hrtime.bigint() / 1000000n : performance.now();

  await smartWait(callback, maxElapsedTimeInMsBeforeDeferring);

  const t1 = isNodeJs ? process.hrtime.bigint() / 1000000n : performance.now();

  console.info(
    `Execution time (${description}): ${
      isNodeJs ? t1 - t0 : Math.floor(t1 - t0)
    }ms`
  );
};
